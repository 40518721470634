import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { getIcon } from "../shared/UtilityFunctions";
import List from "@material-ui/core/List";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  sidebarIcon: {
    minWidth: "40px",
  },
  sidebarLink: {
    textDecoration: "none",
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
}));

function MenuNavItem(props) {
  const theme = useTheme();

  const {
    children,
    href,
    openImmediately = false,
    title,
    handleDrawerOpen,
    handleSetHeader,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(openImmediately);

  const handleClick = () => {
    if (props.isMenuOpen === false) {
      handleDrawerOpen();
    }
    setOpen((oldOpen) => !oldOpen && props.isMenuOpen);
  };

  if (href) {
    return (
      <li>
          <ListItem
            button
            key={title}
            to={href}
            href={href}
            component="a"
            onClick={() => {
              handleSetHeader(props.pathname);
            }}
            className={classes.sidebarLink}
          >
            <ListItemIcon className={classes.sidebarIcon}>
              {getIcon(title, theme)}
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
      </li>
    );
  }

  return (
    <React.Fragment>
      <li
        onFocus={(event) => {
          handleDrawerOpen();
        }}
      >
        <ListItem
          button
          key={title}
          onClick={handleClick}
          className={classes.sidebarLink}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            {getIcon(title, theme)}
          </ListItemIcon>
          <ListItemText primary={title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </li>
      <li>
        <Collapse in={open && props.isMenuOpen} timeout="auto" unmountOnExit>
          <List
            dense={true}
            component="div"
            disablePadding
            className={classes.nested}
          >
            {children}
          </List>
        </Collapse>
      </li>
    </React.Fragment>
  );
}

MenuNavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  linkProps: PropTypes.object,
  onClick: PropTypes.func,
  openImmediately: PropTypes.bool,
  title: PropTypes.string.isRequired,
  topLevel: PropTypes.bool,
};

export default MenuNavItem;
