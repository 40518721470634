import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import MenuNavItem from "./MenuNavItem";
import clsx from "clsx";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { mdiHelpCircle } from "@mdi/js";
import { getIconColor } from "../shared/UtilityFunctions";
import { useTheme } from "@material-ui/core/styles";
import Icon from "@mdi/react";
const drawerWidth = 300;

function renderhelpNavItem(setIsShowHelp) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <List dense={true} className={classes.HelpIconList}>
      <li>
        <ListItem
          button
          key={"Help"}
          onClick={() => {
            setIsShowHelp(true);
          }}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <Icon path={mdiHelpCircle} title="Help" size={1} color={getIconColor(theme)} />
          </ListItemIcon>
          <ListItemText primary={"Help"} />
        </ListItem>
      </li>
    </List>
  );
}

function renderNavItems(options) {
  const { pages, mobileOpen, handleSetHeader, ...params } = options;
  var isMenuOpen = options.props.mobileOpen;
  return (
    <List dense={true}>
      {pages.reduce(
        // eslint-disable-next-line no-use-before-define
        (items, page) =>
          reduceChildRoutes({
            items,
            page,
            isMenuOpen,
            handleSetHeader,
            ...params
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  props,
  activePage,
  isMenuOpen,
  items,
  page,
  depth,
  handleDrawerOpen,
  handleSetHeader,
  t
}) {
  if (page.displayNav === false) {
    return items;
  }
  if (page.children && page.children.length > 0) {
    const topLevel = activePage.pathName.indexOf(`${page.pathName}/`) === 0;
    items.push(
      <MenuNavItem
        linkProps={page.linkProps}
        depth={depth}
        key={page.title}
        topLevel={topLevel && !page.subheader}
        openImmediately={topLevel || Boolean(page.subheader)}
        title={page.title}
        pathname={page.pathName}
        isMenuOpen={isMenuOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleSetHeader={handleSetHeader}
      >
        {renderNavItems({
          props,
          pages: page.children,
          isMenuOpen,
          activePage,
          depth: depth + 1,
          t,
          handleSetHeader
        })}
      </MenuNavItem>
    );
  } else {
    page = page.children && page.children.length === 1 ? page.children[0] : page;

    items.push(
      <MenuNavItem
        linkProps={page.linkProps}
        depth={depth}
        key={page.title}
        title={page.title}
        href={"#/" + page.pathName}
        pathname={page.pathName}
        isMenuOpen={isMenuOpen}
        handleSetHeader={handleSetHeader}
      />
    );
  }

  return items;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 500
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: "0px",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  Links: {
    textDecoration: "none",
    marginLeft: theme.spacing(7)
  },
  SideBarFooter: {
    marginTop: theme.spacing(2.5),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp + 100,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  FooterDivider: {
    marginBottom: theme.spacing(2)
  },
  sidebarIcon: {
    minWidth: "40px"
  },
  sidebarLink: {
    textDecoration: "none",
    color: theme.palette.type === "dark" ? theme.palette.common.white : theme.palette.common.black
  },
  HelpIconList: {
    padding: "0px"
  }
}));

function SideBar(props) {
  const { mobileOpen } = props;

  const handleDrawerOpen = () => {
    props.onDrawerToggle();
  };

  const handleSetHeader = (title) => {
    props.onHeaderChange(title);
    hideHelpUI();
  };

  const showHelpUI = () => {
    props.setIsShowHelp(true);
  };

  const hideHelpUI = () => {
    props.setIsShowHelp(false);
  };

  const classes = useStyles();
  const activePage = {
    displayNav: false,
    pathName: "/",
    title: false
  };

  const pages = props.menuItems;
  return (
    <nav>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: mobileOpen,
          [classes.drawerClose]: !mobileOpen
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: mobileOpen,
            [classes.drawerClose]: !mobileOpen
          })
        }}
      >
        <div className={classes.toolbar} />
        <Divider />
        {renderNavItems({
          props,
          pages,
          activePage,
          depth: 2,
          handleDrawerOpen,
          handleSetHeader
        })}
        {renderhelpNavItem(showHelpUI)}
        {
          <Grow
            in={mobileOpen}
            style={{ transformOrigin: "0 0 0" }}
            {...(mobileOpen ? { timeout: 1000 } : {})}
          >
            <div className={classes.SideBarFooter}>
              <Divider className={classes.FooterDivider} />
              <Link
                href="http://go.microsoft.com/fwlink/?LinkId=518021"
                rel="noopener noreferrer"
                target="_blank"
                onBlur={handleDrawerOpen}
              >
                <Typography variant="body2" noWrap className={classes.Links} gutterBottom>
                MS Data Privacy Notice
                </Typography>
              </Link>
            </div>
          </Grow>
        }
      </Drawer>
    </nav>
  );
}

SideBar.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired
};

export default SideBar;
